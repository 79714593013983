<template>
  <DelayHydration>
    <!-- Hero Banner -->
    <template v-if="frontendCeLayout === 'heroBanner'">
      <Promo
        :header="header"
        :subheader="subheader"
        :header-layout="headerLayout"
        :header-position="headerPosition"
        :header-size="headerSize"
        :bodytext="bodytext"
        :social-menu="socialNavigation"
        :gallery="gallery"
        :button="button"
        class="t3-ce-text-media"
      />
    </template>

    <!-- Video with Title -->
    <template v-else-if="frontendCeLayout === 'videoWithTitle'">
      <VideoWithTitle
        :uid="uid"
        :index="index"
        :appearance="appearance"
        :header="header"
        :subheader="subheader"
        :header-layout="headerLayout"
        :header-size="headerSize"
        :header-position="headerPosition"
        :header-link="headerLink"
        :bodytext="bodytext"
        :button="button"
        :gallery="gallery"
        :lang="currentLocale"
        class="t3-ce-text-media"
      />
    </template>

    <!-- Default -->
    <template v-else>
      <div class="t3-ce-text-media default-text-media">
        <div class="default-text-media__text">
          <UiHeader
            v-if="header"
            :header="header"
            :subheader="subheader"
            :header-layout="headerLayout"
            :header-position="headerPosition"
            :header-size="headerSize"
          />

          <T3HtmlParser :content="bodytext" />
        </div>

        <MediaFile
          v-if="imageFile"
          :file="imageFile"
          class="default-text-media__image"
        />
      </div>
    </template>
  </DelayHydration>
</template>

<script setup lang="ts">
import { computed, defineAsyncComponent, ref } from 'vue'

import { useButton, useCurrentLocale, useT3Api } from '#imports'

import type { T3CeTextGalleryProps } from '~shared/types'

const props = withDefaults(defineProps<T3CeTextGalleryProps>(), {
  uid: 0,
  index: 0,
  appearance: () => ({
    frameClass: '',
    layout: '',
    spaceAfter: '',
    spaceBefore: ''
  }),
  header: '',
  subheader: '',
  headerLayout: 1,
  headerSize: 1,
  headerPosition: '',
  headerLink: '',
  bodytext: '',
  buttonTitle: '',
  buttonColor: 'solid-default',
  buttonSize: 'large',
  buttonWrap: false,
  arrowButton: false,
  buttonLink: ''
})

const currentLocale = useCurrentLocale()
const button = useButton(props)

const { initialData } = useT3Api()

const socialNavigation = computed(() => {
  const navigation = initialData.value?.socialMenu
  return Array.isArray(navigation) ? navigation : []
})

const imageFile = computed(() => {
  return (
    Object.values(props.gallery.rows).flatMap((row) =>
      Object.values(row.columns)
    )?.[0] || null
  )
})

const Promo = defineAsyncComponent(
  () => import('~ui/components/T3Ce/T3CeTextmedia/Promo')
)
const VideoWithTitle = defineAsyncComponent(
  () => import('~ui/components/T3Ce/T3CeTextmedia/VideoWithTitle')
)
const UiHeader = defineAsyncComponent(
  () => import('~ui/components/Ui/UiHeader/UiHeader.vue')
)

const frontendCeLayout = ref(props.appearance?.layout)
</script>

<style lang="scss">
.default-text-media {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;

  @include media-query(md) {
    flex-wrap: nowrap;
  }

  &__text {
    margin: 0 spacing(md);
    text-align: justify;
  }

  &__image {
    width: 100%;

    & figure {
      margin: 0;
    }

    & img {
      display: block;
      margin-inline: auto;
      max-width: 100%;
      object-fit: contain;
    }
  }
}
</style>
